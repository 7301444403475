import { Empty, Flex, Typography } from "antd";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { BlockSkeleton } from "src/components/skeletons";
import styled from "styled-components";

import { interpolate, Route } from "@sol/routing";
import { getResourceUuid, IClassroomItem } from "@sol/sdk";
import { Arrow } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

const PICTURE_PLACEHOLDER = "/static/placeholders/picture_placeholder_purple.svg";

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: var(--ant-padding-sm);
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const ListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid var(--ant-color-border-secondary);
    padding-block: var(--ant-padding-xs);
    padding-left: var(--ant-padding-xs);
    padding-right: var(--ant-padding-lg);
`;

const ClassroomTitle = styled(Typography.Text)`
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    flex: 1;
    flex-shrink: 0;
    text-overflow: ellipsis;
    font-size: var(--ant-font-size-lg);
    font-weight: 700;
`;

type ClassroomListProps = {
    className?: string;
    classrooms?: IClassroomItem[];
    isLoading?: boolean;
};

export function ClassroomsList({ className, classrooms = [], isLoading }: ClassroomListProps) {
    const [t] = useTranslation();

    return (
        <Flex className={clsx("h-full overflow-scroll", className)}>
            {isLoading ? (
                <BlockSkeleton className="rounded-small" />
            ) : classrooms.length > 0 ? (
                <List className="w-full">
                    {classrooms.map(classroom => (
                        <ListItem key={classroom["@id"]} className="max-sm:flex-col max-sm:!p-[var(--ant-padding-sm)]">
                            <Image
                                className="rounded-medium"
                                src={classroom.image?.url || PICTURE_PLACEHOLDER}
                                width={80}
                                height={50}
                                layout="intrinsic"
                                objectFit="cover"
                            />

                            <ClassroomTitle
                                title={classroom.title}
                                aria-label={classroom.title}
                                className="max-sm:text-center"
                            >
                                {classroom.title}
                            </ClassroomTitle>
                            <Link
                                href={interpolate(Route.CLASSROOM_DASHBOARD, {
                                    classroomId: getResourceUuid(classroom),
                                })}
                                passHref
                            >
                                <Button
                                    className="max-w-36 truncate"
                                    size="small"
                                    iconPosition="end"
                                    icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                                    title={t("page.home.trainers.classroomsCard.item.cta.a11y", {
                                        classroomName: classroom.title,
                                    })}
                                    aria-label={t("page.home.trainers.classroomsCard.item.cta.a11y", {
                                        classroomName: classroom.title,
                                    })}
                                >
                                    {t("page.home.trainers.classroomsCard.item.cta.title")}
                                </Button>
                            </Link>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Empty
                    className="m-0 h-16 w-full"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t("component.home.ClassroomList.empty")}
                />
            )}
        </Flex>
    );
}
