import { Typography } from "antd";
import clsx from "clsx";
import { ReactNode, cloneElement, isValidElement } from "react";

type HomeCardProps = {
    className?: string;
    title?: ReactNode;
    image?: ReactNode;
    children: ReactNode;
    action?: ReactNode;
};
export function HomeCard({ className, title, children, image, action }: HomeCardProps) {
    const hasImage = !!image;

    return (
        <section
            className={clsx(
                "rounded-medium bg-[var(--ant-color-bg-container)] px-2 py-4 md:px-4 lg:px-6",
                "flex flex-col gap-4",
                className,
            )}
        >
            {(title || action) && (
                <div className="flex w-full items-center gap-4 max-sm:flex-col md:justify-between">
                    {title && (
                        <Typography.Title
                            level={1}
                            className="!mb-0 font-bold !text-[color:var(--ant-color-primary)] max-sm:text-center max-sm:!text-lg md:!text-lg xl:!text-xl"
                        >
                            {title}
                        </Typography.Title>
                    )}
                    {action ? action : null}
                </div>
            )}
            <div className={clsx("flex flex-1 items-center gap-4 max-md:flex-col lg:gap-8")}>
                {hasImage && isValidElement(image) ? (
                    <>
                        {cloneElement(image, {
                            className: clsx("size-[100px] max-xl:size-20 max-md:size-16", image.props.className),
                        } as any)}
                    </>
                ) : null}
                {children}
            </div>
        </section>
    );
}

type HomeCardDescriptionProps = {
    className?: string;
    label?: string;
    children?: ReactNode;
};

export function HomeCardDescription({ label, children, className }: HomeCardDescriptionProps) {
    return (
        <div className={clsx("flex w-full flex-col gap-2 max-sm:text-center", className)}>
            {label ? (
                <Typography.Title level={2} className="!mb-0 !text-sm !font-bold">
                    {label}
                </Typography.Title>
            ) : null}
            {children ? (
                <Typography.Paragraph className="whitespace-pre-wrap text-xxs">{children}</Typography.Paragraph>
            ) : null}
        </div>
    );
}
